<template>
  <div class="vx-row mt-3 w-full">
    <div class="w-1/2">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>CL Request ID</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="create.referenceCode" disabled />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="territory.selected"
            :options="territory.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            disabled
            name="territory"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('territory')" class="text-danger">{{
            errors.first("territory")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Code</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="customer.selected"
            :options="customer.options"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :internal-search="false"
            :limit="4"
            placeholder=" Type to search"
            track-by="code"
            label="code"
            name="customer"
            :disabled="true"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}{{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >( {{ props.option.code }}){{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('customer')" class="text-danger">{{
            errors.first("customer")
          }}</span>
        </div>
      </div>
      <!-- <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Dist. Channel </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.customer_category"
            disabled
          />
        </div>
      </div> -->
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Group </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.customer_group"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Risk Category</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.risk_category"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current Payment Term</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.credit_limit"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current TOP</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.payment_term"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Highest Transaction</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.highest_transaction"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>PKP</span>
        </div>
        <!-- <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="customerData.is_pkp" disabled />
        </div> -->
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%">KTP Image</vs-divider>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>KTP Image</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 200px; max-width: 400px; padding-left: 20%"
              :src="datasImg.urlKtp"
            />
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%"
          >Outlet Image</vs-divider
        >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Outlet Image</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 200px; max-width: 400px; padding-left: 20%"
              :src="datasImg.urlOutlet"
            />
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%">NPWP Image</vs-divider>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>NPWP Image</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 200px; max-width: 400px; padding-left: 20%"
              :src="datasImg.urlNpwp"
            />
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%"
          >SPPKP Image</vs-divider
        >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>SPPKP Image</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 200px; max-width: 400px; padding-left: 20%"
              :src="datasImg.urlSppkp"
            />
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%"
          >Invoice Image</vs-divider
        >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Invoice Image</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 200px; max-width: 400px; padding-left: 20%"
              :src="datasImg.urlInvoice"
            />
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%">CL Image</vs-divider>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>CL Image</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 200px; max-width: 400px; padding-left: 20%"
              :src="datasImg.urlCl"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-1/2">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Valid From</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <flat-pickr
            :config="configDatePicker"
            v-model="create.dateApply"
            placeholder="Valid From"
            style="width: 100%"
            name="ValidFrom"
            disabled
            v-validate="'required'"
          />
          <span v-show="errors.has('ValidFrom')" class="text-danger">
            Valid From is required
          </span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Notes</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            class="w-full"
            v-model="create.notes"
            name="notes"
            v-validate="'required'"
            disabled
          />
          <span v-show="errors.has('notes')" class="text-danger">{{
            errors.first("notes")
          }}</span>
        </div>
      </div>
      <div class="vx-row" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3">
          <label
            >Attachment
            <small style="color: red"
              >(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small
            ></label
          >
        </div>
        <div class="vx-col sm:w-2/3">
          <div class="vx-col w-1/2">
            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
            <!-- <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls"
            /> -->
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/4 w-full">
          <span></span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <vs-divider style="width: 60%; margin-left: 30%">
          List Attachment
        </vs-divider>
        <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 10%">
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="tr in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="handleShowAttachment(tr)"
                    />
                  </vx-tooltip>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Request</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-radio v-model="create.request" disabled vs-value="credit_limit">
            Credit Limit
          </vs-radio>
          <vs-radio
            v-model="create.request"
            vs-value="payment_term"
            name="request"
            disabled
          >
            Payment Term
          </vs-radio>
          <vs-radio v-model="create.request" disabled vs-value="all" checked>
            All
          </vs-radio>
        </div>
      </div>
      <hr style="border-top: 1px solid black" />
      <div
        class="vx-row margin-btm mt-3"
        style="width: 100%; padding-bottom: 15px"
        v-if="create.request == 'credit_limit' || create.request == 'all'"
      >
        <div class="vx-col sm:w-2/5 w-full">
          <span>Credit Limit Request</span>
        </div>
        <div class="vx-row sm:w-3/5">
          <div class="vx-row sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              disabled
              v-model="create.creditLimitRequest"
            />
            <span v-if="!isCL" class="text-danger">
              * Credit Limit is bigger than current Credit Limit
            </span>
          </div>
          <div class="sm:w-1/3 w-full">
            <vs-input
              class="w-full ml-4"
              v-model="create.paymentTermRequest"
              disabled
            />
          </div>
        </div>
      </div>
      <div
        class="vx-row margin-btm mt-3"
        style="width: 100%; padding-bottom: 15px"
        v-if="create.request == 'payment_term' || create.request == 'all'"
      >
        <div class="vx-col sm:w-1/3 w-full">
          <span>TOP Request</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="top.selected"
            :options="top.options"
            :show-labels="false"
            disabled
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            name="topData"
            label="name"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('topData')" class="text-danger"
            >TOP is required</span
          >
          <span v-if="!isTop" class="text-danger">
            * TOP is bigger than current TOP
          </span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Reason</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="reason.selected"
            :options="reason.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            disabled
            label="reason"
            name="reason"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.reason }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.reason }}</span>
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('reason')" class="text-danger">{{
            errors.first("reason")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current Credit Limit</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.credit_limit"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current TOP</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.payment_term"
            disabled
          />
        </div>
      </div>

      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Evaluation Periode</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="EvaPeriod"
            type="number"
            name="evaluation_period"
            min="0"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Exclude SO Type</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="soType.selected"
            :options="soType.options"
            :show-labels="false"
            multiple
            disabled
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="Code"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.Code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.Code }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>

      <hr style="border-top: 1px solid black" />

      <div
        class="vx-row margin-btm mt-3"
        style="width: 100%; padding-bottom: 15px"
      >
        <div class="vx-col sm:w-1/3 w-full">
          <span>Total Sales (Amount) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.total_sales"
            disabled
          />
        </div>
      </div>

      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Average Sales (Amount)</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            class="w-full"
            auto-grow
            outlined
            v-model="calculateData.average_sales"
            disabled
          >
          </vs-textarea>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Average Payment (Day)</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full" style="height: max-content">
          <vs-input
            class="w-full"
            v-model="calculateData.average_payment"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Number of Late Payment (Invoice) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.numb_of_late_payment"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Average Late Payment (Day) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.average_late_payment"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Active Transaction (Invoice)</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.active_transaction"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>AR Overdue (Amount) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.ar_over_due"
            disabled
          />
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="isOpen"
      >
        <div class="vx-row w-full gap-3">
          <vs-button
            @click="handleSetNotes('approved')"
            color="success"
            icon-pack="feather"
            icon="icon-check"
          >
            Approved
          </vs-button>
          <vs-button
            @click="handleSetNotes('rejected')"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
          >
            Rejected
          </vs-button>
          <vs-button
            @click="handleSetNotes('inquiry')"
            color="warning"
            icon-pack="feather"
            icon="icon-alert-triangle"
          >
            Inquiry
          </vs-button>
        </div>
      </div>
    </div>
    <vs-popup title="Notes" :active.sync="active3">
      <div class="vx-row mb-6">
        <vs-textarea
          v-model="notes"
          placeholder="Notes"
          class="w-full ml-2 mr-2 mb-2"
        ></vs-textarea>

        <div class="vx-col w-full gap-3">
          <vs-button v-on:click="submit()" color="primary">Save</vs-button>
          <vs-button v-on:click="closeDetail()" color="danger"
            >Cancel</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  components: { flatPickr },
  props: {
    id: Number,
    isOpen: Boolean,
  },
  async mounted() {
    await this.getData();
  },
  data() {
    return {
      datasImg: {
        img_ktp: null,
        img_outlet: null,
        img_npwp: null,
        img_sppkp: null,
        img_invoice: null,
        img_additional: null,
        urlKtp: "",
        urlNpwp: "",
        urlSppkp: "",
        urlInvoice: "",
        urlOutlet: "",
        urlCl: "",
      },
      historyData: [],
      history: false,
      totalSales: 0,
      isCalculate: false,
      EvaPeriod: 0,
      create: {
        referenceCode: null,
        dateApply: "",
        amount: 0,
        notes: null,
        paymentTermRequest: null,
        creditLimitRequest: null,
        request: null,
        creditLimitRequestOLD: 0,
      },
      territory: {
        selected: null,
        options: [],
      },
      customer: {
        selected: null,
        options: [],
        search: "",
      },
      top: {
        selected: null,
        options: [],
      },
      reason: {
        selected: null,
        options: [],
      },
      soType: {
        selected: [],
        options: [],
      },
      customerData: {},
      calculateData: {},

      configDatePicker: {
        dateFormat: "d F Y",
      },
      imageDescription: {
        ktp: "ktp",
        outlet: "outlet",
        npwp: "npwp",
        sppkp: "sppkp",
        invoice: "invoice",
        clImage: "cl_image",
      },
      defaultNotes:
        "Average Sales (Amount) = Total Sales (Amount) / Evaluation Period",
      fileAttachment: [],
      newAttachment: [],
      isTop: true,
      isCL: true,
      changeCustomer: 0,
      id_approve: [],
      id_reject: [],
      id_inquiry: [],
      notes: "",
      active3: false,
    };
  },
  methods: {
    closeDetail() {
      this.active3 = false;
      this.id_approve = [];
      this.id_reject = [];
      this.id_inquiry = [];
      this.notes = "";
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    submittest() {
      this.active3 = false;
    },
    dateFormat2(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMMM YYYY");
      }
      return a;
    },

    async getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/credit-limit-requests-id", {
          params: {
            id: this.id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.create.referenceCode = resp.data.credit_limit_request.Code;
            this.create.dateApply = this.dateFormat2(
              resp.data.credit_limit_request.ValidFrom
            );
            this.create.notes = resp.data.credit_limit_request.Notes;
            if (resp.data.credit_limit_request.RequestType == 1) {
              this.create.request = "credit_limit";
            } else if (resp.data.credit_limit_request.RequestType == 2) {
              this.create.request = "payment_term";
            } else {
              this.create.request = "all";
            }
            this.create.creditLimitRequestOLD =
              resp.data.credit_limit_request.CreditLimitRequest;
            this.create.creditLimitRequest = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(resp.data.credit_limit_request.CreditLimitRequest);
            this.customer.selected = {
              id: resp.data.credit_limit_request.CustomerID,
              name: resp.data.credit_limit_request.CustomerName,
              code: resp.data.credit_limit_request.CustomerCode,
            };
            this.territory.selected = {
              id: resp.data.credit_limit_request.TerritoryID,
              name: resp.data.credit_limit_request.TerritoryName,
            };
            this.top.selected = {
              id: resp.data.credit_limit_request.PaymentTermID,
              name: resp.data.credit_limit_request.PaymentTermCode,
            };
            this.reason.selected = {
              id: resp.data.credit_limit_request.ReasonID,
              reason: resp.data.credit_limit_request.ReasonName,
            };
            this.soType.selected = resp.data.credit_limit_request
              .CreditLimitRequestSalesOrderType
              ? resp.data.credit_limit_request.CreditLimitRequestSalesOrderType
                  .length > 0
                ? resp.data.credit_limit_request
                    .CreditLimitRequestSalesOrderType
                : [{ ID: 0, Code: "-" }]
              : [{ ID: 0, Code: "-" }];

            if (resp.data.attachment != null) {
              resp.data.attachment.forEach((el) => {
                this.fileAttachment.push({
                  id: el.ID,
                  NameFile: el.name,
                  PathFile: el.url,
                });
              });
            }

            this.create.paymentTermRequest =
              resp.data.credit_limit_request.PaymentTermRequest;
            this.customerData.credit_limit_request_old =
              resp.data.credit_limit_request.CurrentCreditLimit;
            this.customerData.credit_limit =
              resp.data.credit_limit_request.CurrentCreditLimit;
            this.customerData.payment_term =
              resp.data.credit_limit_request.CurrentPaymentTerm;
            console.log(
              "OOOOO",
              resp.data.credit_limit_request.EvaluationPeriod
            );
            this.customerData.evaluation_period =
              resp.data.credit_limit_request.EvaluationPeriod;
            this.EvaPeriod = resp.data.credit_limit_request.EvaluationPeriod;

            this.isCalculate = true;
            this.getAllCustomerByID();
            this.handleCalculate();

            // if (resp.data.Status == 3) {
            //   this.getHistoryApproval();
            // }

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    getAllCustomerByID() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-by-id", {
          params: {
            id: this.customer.selected.id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerData = resp.data;
            if (this.customerData.is_pkp == "1") {
              this.customerData.is_pkp = "YES";
            } else {
              this.customerData.is_pkp = "NO";
            }

            this.customerData.credit_limit_request_old =
              this.customerData.credit_limit;
            let cust_credit_limit = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(this.customerData.credit_limit);
            this.customerData.credit_limit = cust_credit_limit;

            let cust_highest_transaction = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(this.customerData.highest_transaction);
            this.customerData.highest_transaction =
              this.customerData.highest_transaction_date +
              " - " +
              cust_highest_transaction;
            let customer_group = "";
            //convert customer group 1,2,3 until pricing group to be like this GT-A4-B0C-C0K-E06
            if (this.customerData.customer_group1 != null) {
              customer_group += this.customerData.customer_group1;
            }
            if (this.customerData.customer_group2 != null) {
              customer_group += "-" + this.customerData.customer_group2;
            }
            if (this.customerData.customer_group3 != null) {
              customer_group += "-" + this.customerData.customer_group3;
            }
            if (this.customerData.pricing_group != null) {
              customer_group += "-" + this.customerData.pricing_group;
            }
            this.customerData.customer_group = customer_group;
            let risk_category = "";
            if (this.customerData.risk_category_code != null) {
              risk_category += this.customerData.risk_category_code;
              risk_category += " - " + this.customerData.risk_category_name;
            }
            this.customerData.risk_category = risk_category;
            // this.customerData.evaluation_period =
            //   resp.data.evaluation_period != 0
            //     ? resp.data.evaluation_period
            //     : 6;
            for (let i = 0; i < this.customerData.customer_image.length; i++) {
              if (this.customerData.customer_image[i].description == "ktp") {
                this.datasImg.urlKtp =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_ktp = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "outlet"
              ) {
                this.datasImg.urlOutlet =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_outlet = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "npwp"
              ) {
                this.datasImg.urlNpwp =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_npwp = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "sppkp"
              ) {
                this.datasImg.urlSppkp =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_sppkp = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "invoice"
              ) {
                this.datasImg.urlInvoice =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_invoice = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "cl_image"
              ) {
                this.datasImg.urlCl =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_cl = this.customerData.customer_image[i];
              } else {
                continue;
              }
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleCalculate() {
      // if (
      //   this.customerData.evaluation_period == null ||
      //   this.customerData.evaluation_period <= 0
      // ) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Required",
      //     text: "Evaluation Periode is required or bigger than 0",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return;
      // }
      // if (this.customer.selected == null) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Required",
      //     text: "Customer is required",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return;
      // }
      // if (
      //   this.customerData.credit_limit == 0 ||
      //   this.customerData.credit_limit == null
      // ) {
      //   this.calculateData = {
      //     total_sales: 0,
      //     average_sales: "-",
      //     average_payment: 0,
      //     numb_of_late_payment: 0,
      //     average_late_payment: 0,
      //     active_transaction: 0,
      //     ar_over_due: 0,
      //     average_sales_response: 0,
      //   };
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Required",
      //     text: "Current Credit Limit is 0 that mean customer is not pass credit limit",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });

      //   return;
      // }
      // if (this.soType.selected.length == 0) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Required",
      //     text: "SoType is required",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return;
      // }
      this.totalSales = 0;
      this.totalAverageSales = 0;

      if (this.soType.selected.length == 0) {
        this.soType.selected = [{ ID: 0, Code: "-" }];
      }

      this.$vs.loading();
      this.$http
        .get("/api/v1/master/calculate-credit-risk", {
          params: {
            sales_order_type:
              this.soType.selected.length > 0
                ? this.soType.selected.map((item) => item.ID)
                : null,
            customer_id: this.customer.selected.id,
            evaluation_period: this.customerData.evaluation_period,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            // this.$vs.notify({
            //   color: "success",
            //   title: "Success",
            //   text: resp.message,
            //   position: "top-right",
            //   iconPack: "feather",
            //   icon: "icon-check-circle",
            // });
            this.calculateData = resp.data;
            this.totalSales = resp.data.total_sales;
            let avg_sales = "";

            this.calculateData.total_average_sales_old =
              resp.data.total_average_sales;
            avg_sales =
              "Total average = " +
              Intl.NumberFormat("en", {
                minimumFractionDigits: 2,
              }).format(resp.data.total_average_sales);
            for (let i = 0; i < resp.data.average_sales_response.length; i++) {
              avg_sales +=
                "\n" +
                new Date(
                  new Date().setMonth(
                    resp.data.average_sales_response[i].month - 1
                  )
                ).toLocaleString("default", { month: "long" }) +
                " =" +
                Intl.NumberFormat("en", {
                  minimumFractionDigits: 2,
                }).format(resp.data.average_sales_response[i].month_total);
            }
            this.calculateData.average_sales = avg_sales;

            this.calculateData.ar_over_due_old = resp.data.ar_over_due;
            this.calculateData.ar_over_due = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(resp.data.ar_over_due);

            this.calculateData.total_sales_old = resp.data.total_sales;
            this.calculateData.total_sales = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(resp.data.total_sales);

            this.isCalculate = true;
            this.$vs.loading.close();
          } else {
            this.calculateData = {
              total_sales: 0,
              average_sales: "-",
              average_payment: 0,
              numb_of_late_payment: 0,
              average_late_payment: 0,
              active_transaction: 0,
              ar_over_due: 0,
              average_sales_response: 0,
            };
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }

          this.$vs.loading.close();
        });
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = file.PathFile;

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    getHistoryApproval() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/credit-limit-requests/approval-history", {
          params: {
            id: this.id,
            code: this.create.referenceCode,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.$vs.loading.close();
            if (resp.data.length > 0) {
              for (let index = 0; index < resp.data.length; index++) {
                if (
                  resp.data[index].status == 0 &&
                  resp.data[index].keterangan == ""
                ) {
                  resp.data[index].Status = "Pending";
                } else if (
                  resp.data[index].status == 0 &&
                  resp.data[index].keterangan != ""
                ) {
                  resp.data[index].Status = "Inquiry";
                } else if (resp.data[index].status == 1) {
                  resp.data[index].Status = "Approved";
                } else {
                  resp.data[index].Status = "Rejected";
                }
              }
            }
            this.historyData = resp.data;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    submit() {
      this.active3 = false;

      if (this.notes == "" && this.id_inquiry.length != 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Notes is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      if (this.notes == "" && this.id_reject.length != 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Notes is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      this.$vs.loading();
      let param = {
        crl_id_approve: this.id_approve,
        crl_id_reject: this.id_reject,
        crl_id_inquiry: this.id_inquiry,
        notes: this.notes,
      };
      this.$http
        .post("/api/v1/master/credit-limit-requests/approve", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.closeDetail();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            window.scrollTo(0, 0);
            this.$emit("close");
          } else {
            this.closeDetail();

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleSetNotes(button) {
      console.log("yyyyy", button);
      if (button == "approved") {
        this.id_approve.push(this.id);
        this.active3 = true;
      } else if (button == "rejected") {
        this.id_reject.push(this.id);
        this.active3 = true;
      } else if (button == "inquiry") {
        this.id_inquiry.push(this.id);
        this.active3 = true;
      }
    },
  },
  watch: {
    //if isactive is false then set array to empty
    "create.territory": function (val) {
      if (val) {
        this.errors.clear();
        this.create.territory = val.id ? val.id : val.ID;
      } else {
        this.create.territory = null;
      }
    },
    "customer.selected": function (val) {
      if (val) {
        this.IDAttachment = [];
        this.isCalculate = false;
        this.calculateData = {
          total_sales: 0,
          average_sales: "-",
          average_payment: 0,
          numb_of_late_payment: 0,
          average_late_payment: 0,
          active_transaction: 0,
          ar_over_due: 0,
          average_sales_response: 0,
        };
        this.changeCustomer += 1;
        if (this.changeCustomer > 1) {
          this.create.creditLimitRequest = 0;
          this.create.paymentTermRequest = "";
          this.create.dateApply = null;
          this.create.notes = "";
          this.create.request = "";
          this.top.selected = { id: 0, name: "" };

          this.reason.selected = { reason: "", id: 0 };
          this.fileAttachment = [];
          this.datasImg = {
            img_ktp: null,
            img_outlet: null,
            img_npwp: null,
            img_sppkp: null,
            img_invoice: null,
            img_cl: null,
            urlKtp: "",
            urlOutlet: "",
            urlNpwp: "",
            urlSppkp: "",
            urlInvoice: "",
            urlCl: "",
          };
          this.IDAttachment = [];
        }

        if (val.id) {
          this.getAllCustomerByID();
        } else {
          this.customerData = {};
        }
      }
    },
    "create.creditLimitRequestOLD": function (val) {
      if (this.customerData.credit_limit_request_old !== 0) {
        // Hitung perubahan persentase
        this.percentageChange =
          ((val - this.customerData.credit_limit_request_old) /
            this.customerData.credit_limit_request_old) *
          100;

        this.create.paymentTermRequest = this.percentageChange.toFixed(2) + "%";
      }
    },
    "create.dateApply": function (val) {
      if (val) {
        this.errors.clear();
        this.create.dateApply = val;
        console.log("ini lognya", this.create.dateApply);
      } else {
        this.create.dateApply = null;
      }
    },
    "soType.selected": function () {
      if (this.soType.selected.length > 1) {
        for (let index = 0; index < this.soType.selected.length; index++) {
          if (this.soType.selected[index].ID == 0) {
            this.soType.selected = [{ ID: 0, Code: "-" }];
          }
        }
      }
    },
    // "customerData.evaluation_period": function () {
    //   if (this.customerData.evaluation_period < 3) {
    //     this.$vs.notify({
    //       color: "warning",
    //       title: "Warning",
    //       text: "Evaluation Period must be greater than 3",
    //       position: "top-right",
    //       iconPack: "feather",
    //       icon: "icon-check-circle",
    //     });
    //   }
    // },
    customerData: function () {
      if (this.customerData.credit_limit == 0) {
        this.create.creditLimitRequest = 0;
      }
    },
    "top.selected": function (val) {
      if (val) {
        //check if top selected is bigger than current top
        console.log("new top", val.due_invoice_period);
        console.log("current top", this.customerData.payment_term_due_periode);
        if (
          val.due_invoice_period > this.customerData.payment_term_due_periode
        ) {
          this.isTop = false;
        } else {
          this.isTop = true;
        }
      }
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        //format date 2024-03-25T21:01:23Z to 25 Mar 2024 21:01
        return moment.utc(value).format("DD MMM YYYY HH:mm");
      }
    },
  },
};
</script>
<style>
.container {
  display: flex;
  justify-content: space-between;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
}
</style>
